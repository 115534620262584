
import { createSlice } from "@reduxjs/toolkit";
import checkOutPaymentCruds from '../../_apis/cruds/checkOutPaymentCrud'
import  {  logAPIResponse } from "../../helpers/LogCartData";
import { formatError } from "../../helpers/Utils";
const REDUCER_NAME = "checkOut"
const TYPES = {
    PAYMENT: 'payment',
}

export const checkoutSlice = createSlice({
    name: REDUCER_NAME,
    initialState: {
        ...Object.values(TYPES).reduce((occ, cur) => { return ({ ...occ, [cur]: { loading: false, error: null, data: null } }) }, {})
    },
    reducers: {
        catchError: (state, action) => {
            state[action.payload.type].loading = false;
            state[action.payload.type].error = action.payload.error;
        },
        startCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = true;
        },
        stopCall: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
        },
        dataRecieved: (state, action) => {
            state[action.payload.type].error = null;
            state[action.payload.type].loading = false;
            state[action.payload.type].data = action.payload.data;
        },
    },
})

const startCall = (type) => checkoutSlice.actions.startCall({ type })
const stopCall = (type) => checkoutSlice.actions.stopCall({ type })
const dataRecieved = (type, data) => checkoutSlice.actions.dataRecieved({ type, data })
const catchError = (type, error) => checkoutSlice.actions.catchError({ type, error })
export const checkoutActions = {
    pay: (orderAmount, orderId, paymentMethod) => async (dispatch) => {
        dispatch(startCall(TYPES.PAYMENT));
    
        let data = {
            param: {},
            body: {
                orderAmount,
                orderId,
                paymentMethod,
            },
        };
    
        logAPIResponse(data.body, "Card payment payload");
    
        try {
            const res = await checkOutPaymentCruds.createPayment(data);
            dispatch(dataRecieved(TYPES.PAYMENT, res));
    
            logAPIResponse(res, "Card payment response");
            
            console.log("Response", res)
            return res; // Return the response so the frontend can handle 3D Secure
        } catch (err) {
            let errorString = "Card payment Fail: " + formatError(err);
            dispatch(catchError(TYPES.PAYMENT, err));
            logAPIResponse(data.body, errorString);
            return Promise.reject(err);
        }
    }
    
}